<template>
  <div class="modalClass" ref="container">
    <div class="custom-board" v-show="showCustomBoard">
      <a-input
        style="width: 100%; line-height: 1"
        v-model:value="customTimeDay"
        placeholder="请输入提前天数"
        type="number"
      />
      <a-time-picker style="width: 100%" v-model:value="customTimePick" />
      <div class="custom-row">
        <a-button @click="closeBoard">取消</a-button>
        <a-button @click="addEarlyList" type="primary">确认</a-button>
      </div>
    </div>
    <a-modal
      :visible="visible"
      :title="form.id ? '编辑待办' : '新增待办'"
      :mask-closable="false"
      :width="564"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
      :getContainer="() => container"
      class="issModal"
    >
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="form"
        :rules="rules"
      >
        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="form.content"
            placeholder="请输入待办内容"
            showCount
            :maxlength="200"
            :rows="4"
          />
        </a-form-item>
        <a-button>创建联系人</a-button>
        <!--      下拉选择中才显示在这里    -->
        <div class="dataListClass">
          <div v-for="(item, index) in state.dataList" :key="item.id">
            <div class="name">
              <!--              孙大松 -->
              {{ item.name }}
              <CloseOutlined @click="deleteName(index)" />
            </div>
          </div>
        </div>

        <a-form-item label="" class="formItemClass">
          <!--       输入框输入值，调取接口，获取数据，选择数据是个list,然后遍历list展示   -->
          <a-input
            v-model:value="form.inputValue"
            placeholder="请添加联系人"
            @change="handleChange(form.inputValue)"
            @blur="handleBlur()"
            @focus="handleFocus()"
            :allowClear="true"
          >
          </a-input>
          <!-- 这里需要展示下拉  :class="{selectListtNone: form.contactIdList.length > 0}"  -->

          <div v-if="state.showxiala" class="namename">
            <div v-for="i in state.selectNameList" :key="i.id">
              <div class="selectList">
                <div class="selectName" @click="selectName(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </div>
        </a-form-item>

        <a-row>
          <a-col :span="12">
            <a-form-item label="" name="reminderTime">
              <a-date-picker
                v-model:value="form.reminderTime"
                :getCalendarContainer="triggerNode => triggerNode.parentNode"
                value-format="yyyy-MM-DD HH:mm:ss"
                show-time
                placeholder="请选择"
                style="width: 100%"
                class="phoneCalssRight"
                :inputReadOnly="true"
                @blur="handleBlur('reminderTime')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" class="colCalss">
            <!--   todo 提前提醒时间    -->
            <a-form-item label="" name="reminderCycle">
              <a-select
                v-model:value="form.reminderCycle"
                placeholder="请选择"
                :open="earlyListOpen"
                :options="
                  spEarlyList.map(item => ({
                    value: item.itemCode,
                    label: item.itemNameCn,
                  }))
                "
                @click="earlyListOpen = true"
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <a-space>
                    <a-button
                      class="custom-btn"
                      style="padding: 3px 10px"
                      type="text"
                      @click="chooseTime"
                      @mousedown.prevent
                      ref="customBtn"
                    >
                      自定义
                    </a-button>
                  </a-space>
                  <div class="custom-row">
                    <a-button @click="closeEarlySelect">取消</a-button>
                    <a-button @click="closeEarlySelect" type="primary"
                      >确认</a-button
                    >
                  </div>
                </template>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <!--   提醒方式   -->
        <a-form-item label="" name="reminderMethodList">
          <a-select
            mode="multiple"
            autoClearSearchValue
            v-model:value="form.reminderMethodList"
            placeholder="请选择"
            :filter-option="
              (inputValue, option) =>
                handleFilterOption(inputValue, option, item)
            "
          >
            <a-select-option
              v-for="(item, index) in state.remindList"
              :key="index"
              :value="item.itemCode"
            >
              {{ item.itemNameCn }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-for="(item, index) in form.subItemsList"
          :key="item.key"
          :label="index === 0 ? '' : ''"
          :name="['subItemsList', index, 'phone']"
          :rules="rulesPhone"
          :class="{
            bt: index === 0 ? true : false,
          }"
          class="subItem"
        >
          <a-input
            v-model:value="item.subContent"
            placeholder="请输入子待办"
            style="width: 93%; margin-right: 8px; height: 32px"
          />
          <!--      :disabled="dynamicValidateForm.domains.length === 1"
                       v-if="dynamicValidateForm.domains.length > 1"
            -->
          <MinusCircleFilled
            class="dynamic-delete-button"
            @click="removeDomain(item)"
          />
        </a-form-item>
        <a-form-item class="subItemBut">
          <a-button type="dashed" style="width: 100%" @click="addDomain">
            <PlusOutlined />
            添加待办事项
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import {
  MinusCircleFilled,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import {
  Form as AForm,
  message,
  DatePicker as ADatePicker,
  TimePicker as ATimePicker,
  Select as ASelect,
  Row as ARow,
  Col as ACol,
} from 'ant-design-vue';
import {
  reactive,
  watch,
  onMounted,
  ref,
  onBeforeUnmount,
  defineProps,
  defineEmits,
  defineComponent,
} from 'vue';
import { useStore } from 'vuex';
import contactAll from '@/api/contactAll';
import todayApi from '@/api/today';
// import {useRouter} from "vue-router";

const AFormItem = AForm.Item;
const ASelectOption = ASelect.Option;

const props = defineProps({
  visible: Boolean,
  init: Object,
});

const emits = defineEmits(['fnOk']);

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

// const router = useRouter();
const container = ref();
const formRef = ref();
const store = useStore();
const userId = store.state.account.user.id;
console.log('userId', userId);
const form = reactive({
  content: '',
  leadPool: [],
  reminderTime: null,
  reminderCycle: null,
  // 自定义准时
  inAdvanceDays: null,
  time: null,
  // 人员类型 0-个人 1-脉圈
  personnelType: null,
  contactIdList: [],
  connectionsId: '',
  contentName: [],
  // 子待办数组
  subItemsList: [],
  type: '',
  dataType: '',
  // 主待办id
  todoId: '',
  userId: store.state.account.user.id,
  reminderMethodList: [],
  inputValue: '',
});

const state = reactive({
  earlyList: [],
  remindList: [],
  // 联系人下拉
  contactList: [],
  len: false,
  selectNameList: [],
  dataList: [],
  showxiala: false,
});

const time_casual = ref();
const customTimeDay = ref();
const customTimePick = ref();
const earlyListOpen = ref(false);
const spEarlyList = ref([]);
const customBtn = ref();
const boardLeft = ref(0);
const boardTop = ref(0);
const showCustomBoard = ref(false);

watch(
  () => props.init,
  init => {
    console.log('init12399', init);
    // 在联系人详情或者脉圈详情中添加得待办，需要拿到当下联系人id或脉圈详情id添加到 contactIdList
    if (init.contactId) {
      form.personnelType = 0;
    }
    if (init.id) {
      todayApi.getMatter('', init.id).then(res => {
        if (res) {
          console.log('查询', res);
          Object.assign(form, res);
          if (res.reminderMethodList) {
            form.reminderMethodList = res.reminderMethodList;
          } else {
            form.reminderMethodList = [];
          }
        }
      });

      state.dataList = init.personnelVoList;
    }
  },
  { deep: true }
);

// // 全局创建一个点击事件:bodyCloseMenus
onMounted(() => {
  // console.log('点击事件555', )
  // document.addEventListener('click', colseSift);

  // 提前提醒时间
  contactAll.getTree('', { code: 'REMINDER_CYCLE' }).then(res => {
    if (res) {
      state.earlyList = res;

      state.earlyList.forEach(item => {
        if (item.itemCode != 'custom') {
          spEarlyList.value.push(item);
        }
      });
    }
  });

  // 提醒方式
  contactAll.getTree('', { code: 'REMINDER_METHOD' }).then(res => {
    if (res) {
      state.remindList = res;
    }
  });
});
// // 在页面注销前，将点击事件给移除
onBeforeUnmount(() => {
  // console.log('点击事件66', )
  // document.removeEventListener('click', colseSift);
});

const rules = ref({
  content: [
    {
      required: true,
      message: '请输入待办内容',
      trigger: 'change',
    },
    { max: 200, message: '字符长度不能超过48' },
  ],
  reminderTime: [
    {
      required: true,
      message: '不能为空',
      trigger: 'change',
    },
  ],
});

const closeBoard = () => {
  setTimeout(() => {
    showCustomBoard.value = false;
  }, 100);
};

const closeEarlySelect = () => {
  setTimeout(() => {
    earlyListOpen.value = false;
    showCustomBoard.value = false;
  }, 100);
};

const rulesPhone = ref({
  // required: true,
  // message: 'domain can not be null',
  trigger: 'change',
  validator: async (rule, value) => {
    // console.log('rule', rule, value);
    let maxLength50Regex = /^.{1,40}$/;
    if (!maxLength50Regex.test(value)) {
      return Promise.reject('子待办最多只能输入40个字符');
    }
  },
});

const removeDomain = item => {
  console.log('item', item, form.subItemsList);

  let index = form.subItemsList.indexOf(item);
  if (index !== -1) {
    form.subItemsList.splice(index, 1);
  }
};

const addDomain = () => {
  if (form.subItemsList.length === 6) {
    return message.error('最多只可设置6个子待办');
  } else {
    form.subItemsList.push({
      subContent: '',
      key: Date.now(),
      // 待办是否完成 0-未完成 1-已完成
      completed: null,
    });
  }
};

const handleCancel = () => {
  closeEarlySelect();
  emits('fnOk', false);
};

const getData = res => {
  // state.showxiala = true
  // Object.assign(state.selectNameList, res)

  let notFoundItems = []; // 存储在list数组中存在但在a数组中不存在的项的新数组
  // console.log('state.dataList', state.dataList);
  // console.log('res', res);
  // 遍历 list 数组
  res.forEach(item => {
    // 使用 JSON.stringify 将对象转换为字符串，然后检查当前项是否在 a 数组中
    if (!state.dataList.some(aItem => aItem.id === item.id)) {
      // 如果不在，将该项添加到 notFoundItems 数组中
      notFoundItems.push(item);
    }
  });
  // 打印在list数组中存在但在a数组中不存在的项
  // console.log('notFoundItems', notFoundItems);
  if (notFoundItems.length > 0) {
    state.showxiala = true;
    state.selectNameList = notFoundItems;
  } else {
    state.showxiala = false;
  }
};

const handleChange = value => {
  console.log('222222', value);
  // 联系人下拉 userId: store.state.account.user.id,
  todayApi
    .queryContactByName('', {
      name: value,
    })
    .then(res => {
      if (res) {
        getData(res);
      }
    });
};
const handleOk = () => {
  // 保存之前需要把 state.dataList 处理成 数组中只是含 id, 放在 form.contactIdList
  console.log('form', form);

  //检查提醒时间是否为自定义
  if (form.reminderCycle == 'custom') {
    form.time = time_casual.value;
  } else {
    form.time = null;
  }

  state.dataList.map(item => {
    form.contactIdList.push(item.id);
  });

  if (form.contactIdList.length === 0) {
    return message.error('请添加联系人');
  }

  formRef.value.validateFields().then(() => {
    let type = 'addContactTodoMatter';
    form.id && (type = 'updataTodoMatter');
    // 新增待办
    todayApi[type]('', {
      userId: userId,
      ...form,
    }).then(res => {
      if (res) {
        console.log('res', res);
        message.success('新增待办成功');
        emits('fnOk', false);
        formRef.value.resetFields();

        // 新增完成需要清空的
        state.dataList = [];
        form.subItemsList = [];
      }
    });
  });
};

const deleteName = index => {
  console.log('deleteName', index);
  state.dataList.splice(index, 1);
};

const selectName = item => {
  if (state.dataList.length > 0) {
    state.dataList.forEach(i => {
      if (i.id !== item.id) {
        state.dataList.push(item);
      }
    });
    // 数组对象去重的做法
    let unique = {};
    state.dataList.forEach(item => {
      unique[item['id']] = item;
    });
    state.dataList = Object.values(unique);
  } else {
    state.dataList.push(item);
  }

  form.inputValue = '';
  state.selectNameList = [];
  state.showxiala = false;
};

const handleFilterOption = (inputValue, option) => {
  return option.key.itemNameCn.indexOf(inputValue) >= 0;
};

// 失焦调取清空输入框内容和下拉内容
const handleBlur = () => {};

const handleFocus = () => {
  todayApi.queryContactByName('', {}).then(res => {
    console.log('handleFocus获取焦点', res);
    if (res) {
      state.showxiala = true;
      getData(res);
    }
  });
};

//点击自定义打开时间选择面板
const chooseTime = e => {
  let rect = customBtn.value.$el.getBoundingClientRect();
  showCustomBoard.value = true;
  boardTop.value = rect.bottom - rect.height + 'px';
  boardLeft.value = rect.right + 'px';
};

//往提醒时间列表中添加新的选项
const addEarlyList = () => {
  let hour = customTimePick.value._d.getHours();
  let min = customTimePick.value._d.getMinutes();
  let sec = customTimePick.value._d.getSeconds();
  let arr = {
    itemCode: 'custom',
    itemNameCn: `提前${customTimeDay.value}天(${hour}:${min}:${
      sec > 10 ? sec : '0' + sec
    })`,
  };
  time_casual.value = {
    hour: hour,
    minute: min,
    second: sec,
    nano: customTimeDay.value,
  };
  //将之前添加的自定义时间清除
  spEarlyList.value = spEarlyList.value.filter(item => {
    if (item.itemCode !== 'custom') {
      return item;
    }
  });
  spEarlyList.value.push(arr);
  customTimeDay.value = null;
  customTimePick.value = null;
  closeBoard();
};
</script>

<style lang="less" scoped>
.tabsRight {
  height: 550px;
}
.tabsRight /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
.content {
  //max-height: calc(100vh - 356px);
  //max-height: calc(100vh - 321px);
  //overflow-y: auto;
  height: 475px;
  overflow: auto;
  margin-top: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 0.5px solid #eeeeee;
  .itemOne {
    .jinbi {
      margin-top: 8px;
    }
  }
  .itemTwo {
    width: 18%;
    text-align: right;
  }
}

.noneList {
  margin-top: 100px;
  text-align: center;
  color: #bfbfbf;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 0px;
  font-size: 13px;
  //color: #999;
  color: #ff7b00;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #ff7b00;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.subItemBut /deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px;
}

.modalClass /deep/ .ant-modal-body {
  height: 580px;
  overflow: auto;
  padding: 24px 24px 0px 24px;
}
.colCalss {
  margin-left: 0px;
}
.modalClass /deep/ .ant-modal-footer {
  padding: 14px 24px 24px 24px;
  //padding: 24px;
}
.dataListClass {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-top: 16px;
  max-height: 68px;
  overflow: auto;
  //.nameList {

  .name {
    // width: 88px;
    height: 24px;
    border-radius: 4px;
    //background: #FFFFFF;
    background: #fffaf6;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 8px 0px;
    gap: 4px;
  }

  //}
}

.nameListNone {
  display: none;
}

.formItemClass {
  position: relative;
}

.namename {
  max-height: 164px;
  width: 515px;
  overflow: auto;
  position: absolute;
  top: 24px;
  z-index: 800;
  margin-top: 16px;
  border: 1px solid #eeeff5;
  background: #fff;
  border-radius: 4px;
  .selectList {
    .selectName {
      height: 40px;
      //border-radius: 4px;
      background: #f5f6fa;
      font-size: 12px;
      line-height: 20px;
      //padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0px 8px 8px 0px;
      //padding: 10px 16px;
      cursor: pointer;
      padding-left: 16px;
      &:hover {
        background: #fffaf6;
      }
    }
  }
}

.custom-btn {
  width: 100%;
  position: relative;
  text-align: left;
}

.custom-row {
  width: 100%;
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.custom-board {
  width: 180px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: v-bind(boardTop);
  left: v-bind(boardLeft);
  z-index: 1001;
  // transform: translate(-100%, -100%);

  .custom-row {
    justify-content: space-between;
  }
}
</style>
